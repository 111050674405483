import * as Sentry from "@sentry/nuxt";

if (process.env.NODE_ENV === "production") {
	Sentry.init({
		dsn: "https://dbe817c001b9785d02918f61a238a0d4@o4504844181372928.ingest.us.sentry.io/4507064171560960",
		environment: process.env.NODE_ENV,
		integrations: [
			Sentry.captureConsoleIntegration({
				levels: ["error"],
			}),
			Sentry.browserTracingIntegration(),
			Sentry.browserProfilingIntegration(),
			Sentry.browserTracingIntegration(),
			Sentry.extraErrorDataIntegration(),
			Sentry.replayIntegration(),
		],
		// Session Replay
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
		tracePropagationTargets: ["scholarly.so"],
	});
}
